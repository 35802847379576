import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import React from "react";
import useGameBuyQuantities from "../../../hooks/useGameBuyQuantities";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function QuantityBuyGameForm({ game, locale, workflows }: QuantityBuyGameFormProps) {
    const {t} = useTypeTranslation('game', game);
    const items = useGameBuyQuantities(game, { t, locale, workflows })

    return (
        <ChoiceMenu items={items} />
    )
}

export interface QuantityBuyGameFormProps {
    game: any;
    workflows: any;
    locale: string;
}

export default QuantityBuyGameForm;
