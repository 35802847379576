import React, {useEffect} from "react";
import Spinner from "@gotombola/react-ds/lib/atoms/Spinner";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";

export function ExternalPaymentBuyGameForm({ game, locale }: ExternalPaymentBuyGameFormProps) {
    const query = useQueryString();

    useEffect(() => {
        const n = setTimeout(() => {
            query.set('g', game.code);
            !query.has('p') && game.ticketPriceAmount === 0 && !query.has('d') && query.set('p', 'free');
            locale && !query.has('locale') && query.set('locale', locale);
            location.href = `${process.env.RAZZLE_GOTOMBOLA_API_PAYMENT_ENDPOINT}/payments/new/game-sale?${query.toString()}`;
        }, 1000);
        return () => {
            clearTimeout(n);
        }
    }, [query, game.code, game.ticketPriceAmount, locale])

    return (
        <Spinner />
    );
}

export interface ExternalPaymentBuyGameFormProps {
    game: any;
    workflows: any;
    locale: any;
}

export default ExternalPaymentBuyGameForm;